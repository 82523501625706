/* eslint-disable no-underscore-dangle */
import React from 'react';
import { IReactionDisposer, observable, reaction } from 'mobx';
import { observer, inject } from 'mobx-react';
import autobind from 'autobind-decorator';
// import getConfig from 'next/config';

// import cn from 'classnames';
import { browserOnly } from './EnvSplit';
// import { Trans, withTranslation, WithTranslationType } from '../server/i18n';

// import Popup from './Popup';

const TIMEOUT_DELAY = 2000;

/* eslint-disable @typescript-eslint/no-explicit-any */
declare global {
  interface Window {
    liveagent: any;
    liveAgentDeployment: any;
    _laq: any;
    embeddedservice_bootstrap: any;
  }
}
/* eslint-enable @typescript-eslint/no-explicit-any */

// const {
//   publicRuntimeConfig: { SALESFORCE_SCRIPT_URL },
// } = getConfig();

// const LIVEAGENT_INIT_ID_1 = '5720N0000008aBN';
// const LIVEAGENT_INIT_ID_2 = '00D0N000000gJ18';

interface ChatButtonProps {
  config?: {
    getValue: Function;
  };
  i18nUtils?: {
    umRegion: string;
  };
  initialLoadComplete?: boolean;
  tracker?: {
    track: Function;
  };
  screen?: {
    isDesktop: boolean;
  };
  session?: {
    user: {
      id: number;
      name: string;
    };
  };
}

@browserOnly
@inject(({ store: { config, i18nUtils, initialLoadComplete, screen, session, tracker } }) => ({
  config,
  i18nUtils,
  initialLoadComplete,
  screen,
  session,
  tracker,
}))
@observer
class ChatButton extends React.Component<ChatButtonProps, null> {
  // clickedUpgrade: any;
  // csWorkingHours: any;
  // faqUrl: string;
  listeners: IReactionDisposer[];
  // salesforceButtonId: string;
  timeout: NodeJS.Timeout;
  // zendeskLocale: any;

  @observable hasStartedChat = false;
  @observable isChatOpen = false;
  @observable isUserChat = false;
  @observable showChatOfflinePopup = false;

  // constructor(props: ChatButtonProps) {
  //   super(props);
  //   // const { config, i18nUtils } = this.props;
  // }

  componentDidMount(): void {
    if (!window._laq) {
      window._laq = [];
    }

    const { session } = this.props;
    const { user } = session;

    /* eslint-disable react/destructuring-assignment */
    this.listeners = [
      reaction(
        () => session && session.user && session.user.id,
        id => {
          this.loadChat(id);
        },
        {
          name: 'Create chat session when user signs in',
        },
      ),
      reaction(
        () => this.props.initialLoadComplete,
        initialLoadComplete => {
          if (initialLoadComplete) {
            const userId = (user && user.id) || undefined;

            this.timeout = setTimeout(() => {
              this.loadChat(userId);
            }, TIMEOUT_DELAY);
          }
        },
        {
          fireImmediately: true,
          name: 'Check if content has finished loading',
        },
      ),
    ];
    /* eslint-enable react/destructuring-assignment */
  }

  componentWillUnmount(): void {
    delete window.liveagent;
    delete window.liveAgentDeployment;

    while (this.listeners.length) {
      this.listeners.pop()();
    }

    if (this.timeout) {
      clearTimeout(this.timeout);
      this.timeout = undefined;
    }

    // This mock-agent suppresses errors caused by pending avail-checks
    window.liveagent = { _: { handlePing: (): void => {} } }; // eslint-disable-line
  }

  /*
   *  This function adds a new script from url parameter to the document head and allows a callback
   *  to be called once the script loads
   */
  loadScript(url: string, callback: () => void): void {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const script: any = document.createElement('script');
    script.type = 'text/javascript';

    if (script.readyState) {
      //IE
      script.onreadystatechange = (): void => {
        if (script.readyState === 'loaded' || script.readyState === 'complete') {
          script.onreadystatechange = null;
          callback();
        }
      };
    } else {
      //Others
      script.onload = (): void => {
        callback();
      };
    }

    script.src = url;
    document.getElementsByTagName('head')[0].appendChild(script);
  }

  /*
   *  Reload the Salesforce Live Agent
   */
  @autobind
  loadChat(userId: number): void {
    // We need to delete all the liveagent objects
    delete window.liveagent;
    delete window.liveAgentDeployment;

    // This mock-agent suppresses errors caused by pending avail-checks
    window.liveagent = { _: { handlePing: (): void => {} } }; // eslint-disable-line

    this.loadScript('https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingHeroWebsite1736361168688/assets/js/bootstrap.min.js', () => {
      try {
        console.log('SENDING USER ID', userId);
        window.addEventListener('onEmbeddedMessagingReady', () => {
          console.log('Received the onEmbeddedMessagingReady event.');
          console.log('Settings Object:::', JSON.stringify(window.embeddedservice_bootstrap.settings));
          // Get the page name (document title) or the URL (window.location.href)
          // const pageName = document.title; // Use this for the page title
          const pageURL = window.location.href; // Use this for the full URL
          // Send data to Salesforce
          //For Location, You can use PageName
          window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
            Platform: 'HERO WEBSITE',
            UMUserID: `${userId}`,
            Location: pageURL,
          });
          // Lets populate the Images used for the Messaging Chat branding with our static resources
          const brandings = window.embeddedservice_bootstrap.settings.embeddedServiceConfig.branding;
          console.log('Brandings:', brandings);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < brandings.length; i++) {
            console.log('Branding looping');
            if (brandings[i].n === 'logoImage') {
              // brandings[i].v = `${window.location.origin}/resource/1728182561000/logoImage?`;
              // eslint-disable-next-line id-length
              brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Chat_Logo.png';
              console.log('LogoImage updated');
            } else if (brandings[i].n === 'avatarImage') {
              // brandings[i].v = `${window.location.origin}/resource/1730396270000/AvatarImage?`;
              // eslint-disable-next-line id-length
              brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Agent_Neutral.png';
              console.log('AvatarImage updated');
            } else if (brandings[i].n === 'botImage') {
              // brandings[i].v = `${window.location.origin}/resource/1728177930000/botImage?`;
              // eslint-disable-next-line id-length
              brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Bot.png';
              console.log('BotImage updated');
            }
          }
        });

        window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

        // Use a deployment-specific storage key prefix to isolate sessions
        // window.embeddedservice_bootstrap.settings.storageKeyPrefix = 'MessagingHeroWebsiteClient_DeploymentB'; // Change this for each deployment

        // window.embeddedservice_bootstrap.settings.restrictSessionOnMessagingChannel = true;

        window.embeddedservice_bootstrap.init(
          '00DUE000001FwG7',
          'Messaging_Hero_Website2',
          'https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingHeroWebsite1736361168688',
          {
            scrt2URL: 'https://urbanco--partialcpy.sandbox.my.salesforce-scrt.com',
          },
        );
      } catch (err) {
        console.error('Error loading Embedded Messaging: ', err);
      }
    });
  }

  // FIRST DEPLOYMENT
  // @autobind
  // loadChat(userId: number): void {
  //   // We need to delete all the liveagent objects
  //   delete window.liveagent;
  //   delete window.liveAgentDeployment;

  //   // This mock-agent suppresses errors caused by pending avail-checks
  //   window.liveagent = { _: { handlePing: (): void => {} } }; // eslint-disable-line

  //   this.loadScript('https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingHeroWebsite1735011910966/assets/js/bootstrap.min.js', () => {
  //     try {
  //       console.log('SENDING USER ID', userId);
  //       window.addEventListener('onEmbeddedMessagingReady', () => {
  //         console.log('Received the onEmbeddedMessagingReady event.');
  //         console.log('Settings Object:::', JSON.stringify(window.embeddedservice_bootstrap.settings));
  //         // Get the page name (document title) or the URL (window.location.href)
  //         // const pageName = document.title; // Use this for the page title
  //         const pageURL = window.location.href; // Use this for the full URL
  //         // Send data to Salesforce
  //         //For Location, You can use PageName
  //         window.embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
  //           Platform: 'HERO WEBSITE',
  //           UMUserID: `${userId}`,
  //           Location: pageURL,
  //         });
  //         // Lets populate the Images used for the Messaging Chat branding with our static resources
  //         const brandings = window.embeddedservice_bootstrap.settings.embeddedServiceConfig.branding;
  //         console.log('Brandings:', brandings);
  //         // eslint-disable-next-line no-plusplus
  //         for (let i = 0; i < brandings.length; i++) {
  //           console.log('Branding looping');
  //           if (brandings[i].n === 'logoImage') {
  //             // brandings[i].v = `${window.location.origin}/resource/1728182561000/logoImage?`;
  //             // eslint-disable-next-line id-length
  //             brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Chat_Logo.png';
  //             console.log('LogoImage updated');
  //           } else if (brandings[i].n === 'avatarImage') {
  //             // brandings[i].v = `${window.location.origin}/resource/1730396270000/AvatarImage?`;
  //             // eslint-disable-next-line id-length
  //             brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Agent_Neutral.png';
  //             console.log('AvatarImage updated');
  //           } else if (brandings[i].n === 'botImage') {
  //             // brandings[i].v = `${window.location.origin}/resource/1728177930000/botImage?`;
  //             // eslint-disable-next-line id-length
  //             brandings[i].v = 'https://res.cloudinary.com/huxr6hrje/image/upload/v1734957989/chat-2025/UM_Bot.png';
  //             console.log('BotImage updated');
  //           }
  //         }
  //       });

  //       window.embeddedservice_bootstrap.settings.language = 'en_US'; // For example, enter 'en' or 'en-US'

  //       // Use a deployment-specific storage key prefix to isolate sessions
  //       // window.embeddedservice_bootstrap.settings.storageKeyPrefix = 'MessagingHeroWebsiteClient_DeploymentB'; // Change this for each deployment

  //       // window.embeddedservice_bootstrap.settings.restrictSessionOnMessagingChannel = true;

  //       window.embeddedservice_bootstrap.init(
  //         '00DUE000001FwG7',
  //         'Messaging_Hero_Website',
  //         'https://urbanco--partialcpy.sandbox.my.site.com/ESWMessagingHeroWebsite1735011910966',
  //         {
  //           scrt2URL: 'https://urbanco--partialcpy.sandbox.my.salesforce-scrt.com',
  //         },
  //       );
  //     } catch (err) {
  //       console.error('Error loading Embedded Messaging: ', err);
  //     }
  //   });
  // }


  render(): JSX.Element {
    return (
      <div />
    );
  }
}

export default ChatButton;
